import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
// import Marquee from "react-fast-marquee";
import Image from './Image';
import Login from "./Login";
import AuthContext from "../contexts/AuthContext";

const Navbar: React.FC = () => {
  const { isLoggedIn, avatar, user, logout } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <nav className="flex justify-between items-center bg-transparent text-[#3b4269] p-4 font-bold">
      <div
        className="text-xl"
        style={{ fontFamily: "'Audiowide', sans-serif" }}
      >
        <Link to="/" className="hover:underline text-[#FF7D8B]">
          🌐 Home
        </Link>
      </div>

      <div className="relative">
        {isLoggedIn ? (
          <div
            className="flex items-center cursor-pointer px-4 py-2 bg-[#ff0000] text-white border-2 border-[#FFFF00] hover:bg-[#FFFF00] hover:text-black"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <Image
              src={avatar}
              alt="avatar"
              className="w-10 h-10 rounded-full mr-2"
            />
            <span>{user.username}</span>
            <svg
              className="w-5 h-5 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        ) : (
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="px-4 py-2 bg-[#ff0000] text-white border-2 border-[#ffff00] shadow-md hover:bg-[#ffff00] hover:text-black"
          >
            Login
          </button>
        )}

        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-64 bg-[#FFFF00] text-black p-4 rounded border-2 border-[#FF0000]">
            {isLoggedIn ? (
              <button
                onClick={logout}
                className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Logout
              </button>
            ) : (
              <Login />
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
