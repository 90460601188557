import React from "react";
// import Image from './Image';

// TODO: Store in file or DB probably, and use real data
/*
const commissionsData = [
  {
    tier: "Full Shading",
    price: 60,
    description: "A fully shaded piece with a detailed background.",
    images: [
      {
        name: "Example 1",
        description: "A character with full shading.",
        alt: "Full Shading Example 1",
        link: "https://example.com/image1.jpg",
      },
    ],
  },
  {
    tier: "Line Art",
    price: 30,
    description: "A clean line art drawing with minimal shading.",
    images: [
      {
        name: "Example 2",
        description: "Line art of a character.",
        alt: "Line Art Example 2",
        link: "https://example.com/image2.jpg",
      },
    ],
  },
];
*/

const Commissions = () => {
  return (
    <div>
      {/*
      <div className="space-y-8">
        {commissionsData.map((tier, index) => (
          <div key={index} className="border p-4 rounded-lg">
            <h2 className="text-xl font-semibold">
              {tier.tier} - ${tier.price}
            </h2>
            <p>{tier.description}</p>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {tier.images.map((image, imgIndex) => (
                <div key={imgIndex} className="flex flex-col items-center">
                  <Image
                    src={image.link}
                    alt={image.alt}
                    className="w-40 h-40 object-cover"
                  />
                  <p>{image.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      */}
      <iframe
        src="https://vgen.co/okamicario"
        className="w-full h-screen border-0"
        title="Portfolio"
      ></iframe>
    </div>
  );
};

export default Commissions;
