const base = process.env.REACT_APP_BASE_ASSETS_URL;

const Image = ({
  src,
  alt,
  className = "",
  style = {},
}: {
  src: string;
  alt: string;
  className?: React.HTMLAttributes<HTMLImageElement>["className"];
  style?: React.HTMLAttributes<HTMLImageElement>["style"];
}) => (
  <img
    src={`${base}${src}`}
    alt={alt}
    className={className}
    style={{
      ...style,
    }}
  />
);

export default Image;
