import React from "react";
import getRoundedDecade from "../utils/age";
import OrnateContainer from "./OrnateContainer";
import LinkImagePopup from "./LinkImagePopup";
import ShapeAroundImage from "./ShapeAroundImage";
import Image from "./Image";

const stampList = require.context("/public/img/stamps", true);
const stamps = stampList.keys().map((image: any) => stampList(image));

const AboutMe: React.FC = () => {
  return (
    <div className="about-me-container">
      <h1 className="pixelated-cursive-header" style={{ wordSpacing: "5px" }}>
        Welcome to <span className="highlight-blue">Me</span>
      </h1>

      <div className="about-me-text">
        <Image
          src="/img/me/profile-cropped.png"
          alt="Avatar"
          className="avatar-left"
        />

        <p>
          <span className="highlight-blue">name: </span>Okami
        </p>

        <p>
          <span className="highlight-blue">age: </span>
          {getRoundedDecade(1998)}
        </p>

        <p>
          <span className="highlight-blue">currently doing: </span>building this
          website!
        </p>

        <p>
          <span className="highlight-blue">favourite foods: </span>pasta, steak
        </p>

        <div className="text-center my-4">
          <div className="stamps-container">
            {stamps.map((stamp, index) => (
              <Image
                key={index}
                src={stamp}
                alt={`Stamp ${index + 1}`}
                className="stamp-image"
              />
            ))}
          </div>
        </div>

        <h1 className="pixelated-cursive-header" style={{ wordSpacing: "5px" }}>
          <span className="highlight-blue">Who Am I?</span>
        </h1>

        <p>
          I'm known online as Okami. I'm an antifascist / anarchist silly furry.
          DNI if you're trying to move me to the right!!! I draw art sometimes,
          I code for a living, and I like playing games!
        </p>

        <h1 className="pixelated-cursive-header" style={{ wordSpacing: "5px" }}>
          <span className="highlight-blue">Why This Website?</span>
        </h1>

        <p>
          <ShapeAroundImage
            src="/img/hobbies/sakura-bouncing.gif"
            alt="Cardcaptor Sakura"
            className="p-2 float-left w-24"
          />
          Frankly, I mostly made this on impulse. I've been having very little
          direction in my life and I wanted to build a home that feels like
          "me", for whatever that's worth.
          <ShapeAroundImage
            src="/img/me/borboleta_by_cin_harurun-d5brpy3.gif"
            alt="Borboleta"
            className="p-2 float-right w-48"
          />
          I would like this to be a personal and interesting place where folks
          can learn stuff. I struggle with an inconsistent and fragmented sense
          of self from c-PTSD along with some other problems. I know how lonely
          it is - so hopefully I can do some good onto the world with this.
        </p>

        <br />
        <br />
        <br />

        <div className="flex">
          <OrnateContainer>
            <>
              <h4 className="text-lg font-bold">Stuff I Like Doing</h4>
              <ul className="mt-4">
                <li className="mt-1">
                  getting involved in political discourse &amp; activism
                </li>
                <li className="mt-1">
                  drawing, painting, traditional art, digital art
                </li>
                <li className="mt-1">
                  software engineering (preferably backend stuf)
                </li>
                <li className="mt-1">reading manga &amp; watching anime</li>
                <li className="mt-1">
                  talking about cPTSD and related problems, providing resources
                  to others
                </li>
              </ul>
            </>
          </OrnateContainer>

          <OrnateContainer>
            <h4 className="text-lg font-bold">Comfort Characters</h4>
            <ul className="mt-4">
              <li className="mt-1">
                <LinkImagePopup
                  imageSrc="/img/characters/aak.webp"
                  title="Aak (Arknights)"
                  buttonLabel="Aak"
                />
              </li>
              <li className="mt-1">
                <LinkImagePopup
                  imageSrc="/img/characters/ouma.webp"
                  title="Kokichi Ouma (Dangan Ronpa)"
                  buttonLabel="Kokichi Ouma"
                />
              </li>
              <li className="mt-1">
                <LinkImagePopup
                  imageSrc="/img/characters/shinso.webp"
                  title="Hitoshi Shinso (My Hero Academia)"
                  buttonLabel="Hitoshi Shinso"
                />
              </li>
            </ul>
          </OrnateContainer>

          <span className="inline-block align-middle">
            <Image src="/img/me/by savys.tumblr.com (10).png" alt="Savys" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
