import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights  } from "@vercel/speed-insights/react"

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Library from "./components/Library";
import Resources from "./components/Resources";
import Portfolio from "./components/Portfolio";
import Commissions from "./components/Commissions";
import Home from "./components/Home";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div
          className="flex flex-col h-screen"
          style={{
            cursor:
              "url(/img/cursors/ddr_arrow_01_by_kicked_in_teeth-dbicnxf.png), auto",
          }}
        >
          <Analytics />
          <SpeedInsights />

          <Navbar />
          <div className="flex flex-1">
            <Sidebar />
            <div className="flex-1 p-6 border-l-4 overflow-y-auto">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/library" element={<Library />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/commissions" element={<Commissions />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
