import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { LibraryItem } from "@library/shared-types";
import { fetchWithBackoff } from "../utils/fetchWithBackoff";
import { errorHandler } from "../utils/errorHandler";
import LibraryItemComponent from "./LibraryItemComponent";
import FileModal from "./FileModal";

const Library = () => {
  const [library, setLibrary] = useState<LibraryItem[]>([]);
  const [currentFolder, setCurrentFolder] = useState<LibraryItem | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<LibraryItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [readItems, setReadItems] = useState<string[]>([]);
  const { markItemAsRead, getReadItems } = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const limit = 10;

  useEffect(() => {
    fetchRootFolders();
    loadReadItems();
  }, []);

  const fetchRootFolders = async () => {
    const data = await errorHandler(
      fetchWithBackoff<LibraryItem[]>(
        `${process.env.REACT_APP_API_BASE_URL}/library/root`
      ),
      setError
    );
    if (data) {
      setLibrary(data);
      setLoading(false);
    }
  };

  const fetchFolderContents = async (folder: string, page = 1) => {
    setLoading(true);
    const data = await errorHandler(
      fetchWithBackoff<{ data: LibraryItem[]; totalPages: number }>(
        `${process.env.REACT_APP_API_BASE_URL}/library/folder/${folder}?page=${page}&limit=${limit}`
      ),
      setError
    );
    if (data) {
      if (page === 1) {
        setLibrary(data.data);
      } else {
        setLibrary((prev) => [...prev, ...data.data]);
      }
      setTotalPages(data.totalPages);
    }
    setLoading(false);
  };

  const loadReadItems = async () => {
    const items = await getReadItems();
    setReadItems(items);
  };

  const toggleReadStatus = (item: string) => {
    const isRead = readItems.includes(item);
    markItemAsRead(item, isRead);
    if (isRead) {
      setReadItems((prev) => prev.filter((id) => id !== item));
    } else {
      setReadItems((prev) => [...prev, item]);
    }
  };

  const handleFolderClick = async (folder: LibraryItem) => {
    setBreadcrumbs((prev) => [...prev, folder]);
    setPage(1);
    await fetchFolderContents(folder._id, 1);
    setCurrentFolder(folder);
  };

  const handleBackClick = () => {
    const newBreadcrumbs = breadcrumbs.slice(0, -1);
    setBreadcrumbs(newBreadcrumbs);
    if (newBreadcrumbs.length === 0) {
      fetchRootFolders();
      setCurrentFolder(null);
    } else {
      fetchFolderContents(newBreadcrumbs[newBreadcrumbs.length - 1]._id, 1);
      setCurrentFolder(newBreadcrumbs[newBreadcrumbs.length - 1]);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 100 &&
        !loading &&
        page < totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page, totalPages]);

  useEffect(() => {
    if (page > 1 && currentFolder) {
      fetchFolderContents(currentFolder._id, page);
    }
  }, [page]);

  const handleLoadMoreClick = () => {
    setPage((prev) => prev + 1);
  };

  if (loading && page === 1) {
    return <div>Loading library data...</div>;
  }

  if (error) {
    return <div>Error loading library: {error}</div>;
  }

  return (
    <div className="p-6 bg-pastel-peach rounded-lg shadow-inner pixel-font">
      <div className="mb-4">
        <nav className="text-blue-500 pixel-font">
          <a
            href="#"
            onClick={() => {
              setBreadcrumbs([]);
              fetchRootFolders();
              setCurrentFolder(null);
            }}
            className="hover:underline"
          >
            Library
          </a>
          {breadcrumbs.length > 0 && (
            <>
              {" > "}
              {breadcrumbs.map((folder, index) => (
                <span key={folder._id}>
                  <a
                    href="#"
                    onClick={() => handleBackClick()}
                    className="hover:underline"
                  >
                    {folder.name}
                  </a>
                  {index < breadcrumbs.length - 1 && " > "}
                </span>
              ))}
            </>
          )}
        </nav>
      </div>

      {breadcrumbs.length > 0 && (
        <button
          onClick={handleBackClick}
          className="mb-4 bg-pastel-pink text-white px-3 py-1 rounded pixel-font hover:shadow-inner transform active:translate-y-[2px]"
        >
          Back
        </button>
      )}

      <div className="bg-pastel-peach-dark rounded-lg p-4">
        {library?.map((item: LibraryItem, index: number) => (
          <LibraryItemComponent
            key={item._id}
            item={item}
            toggleReadStatus={toggleReadStatus}
            readItems={readItems}
            onFolderClick={handleFolderClick}
            onFileClick={setSelectedFile}
            level={breadcrumbs.length}
            index={index}
          />
        ))}
      </div>

      {selectedFile && (
        <FileModal
          isOpen={!!selectedFile}
          onRequestClose={() => setSelectedFile(null)}
          fileLink={selectedFile}
        />
      )}

      {!loading && page < totalPages && (
        <button
          onClick={handleLoadMoreClick}
          className="mt-4 bg-pastel-blue text-white px-4 py-2 rounded pixel-font hover:shadow-inner transform active:translate-y-[2px]"
        >
          Load More
        </button>
      )}

      {loading && <div>Loading more...</div>}
    </div>
  );
};

export default Library;
