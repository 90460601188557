import React, { createContext, ReactNode, useEffect, useState } from "react";

interface AuthContextType {
  user: any;
  avatar: string;
  logout: () => void;
  loading: boolean;
  markItemAsRead: (itemID: string, isRead: boolean) => void;
  isLoggedIn: boolean;
  loginWithGoogle: () => void;
  loginWithDiscord: () => void;
  loginWithEmail: (email: string, password: string) => void;
  getReadItems: () => Promise<string[]>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTokenAndProfile = async () => {
      let token = new URLSearchParams(window.location.search).get("token");

      if (!token) {
        token = localStorage.getItem("token");
      }

      if (token) {
        localStorage.setItem("token", token);
        await fetchUserProfile(token);
      }
    };

    fetchTokenAndProfile();
  }, []);

  const fetchUserProfile = async (token: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/@me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setUser(data);
    } catch (error) {
      // TODO: error state
      console.error("Error fetching user profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  const markItemAsRead = async (itemID: string, isRead: boolean) => {
    if (user) {
      const url = `${process.env.REACT_APP_API_BASE_URL}${isRead ? "/users/unmarkAsRead" : "/users/markAsRead"}`;
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemID }),
      });
    } else {
      const readItems = JSON.parse(localStorage.getItem("readItems") || "[]");
      if (isRead) {
        const newReadItems = readItems.filter((id: string) => id !== itemID);
        localStorage.setItem("readItems", JSON.stringify(newReadItems));
      } else {
        readItems.push(itemID);
        localStorage.setItem("readItems", JSON.stringify(readItems));
      }
    }
  };

  const getReadItems = async (): Promise<string[]> => {
    if (user) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/@me`, // TODO: Doesnt have anything attached
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { readItems } = await response.json();
      return readItems || [];
    }
    const localReadItems = JSON.parse(
      localStorage.getItem("readItems") || "[]"
    );
    return localReadItems;
  };

  const loginWithGoogle = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/google`;
  };

  const loginWithDiscord = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/discord`;
  };

  const loginWithEmail = async (email: string, password: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );
    const data = await response.json();
    if (data.access_token) {
      localStorage.setItem("token", data.access_token);
      await fetchUserProfile(data.access_token);
    }
  };

  const isLoggedIn = !!user;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        markItemAsRead,
        isLoggedIn,
        loginWithGoogle,
        loginWithDiscord,
        loginWithEmail,
        getReadItems,
        loading,
        avatar: (user as any)?.avatar || "https://via.placeholder.com/40", // TODO: Typings
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
