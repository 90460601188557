export async function fetchWithBackoff<T>(
  url: string,
  options: RequestInit = {},
  retries: number = 5
): Promise<T> {
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data: T = await response.json();
      return data;
    } catch (err) {
      if (err instanceof Error) {
        if (attempt < retries) {
          console.warn(
            `Attempt ${attempt} failed. Retrying in ${2 ** attempt} seconds...`
          );
          await delay(2 ** attempt * 1000);
        } else {
          throw new Error(`Failed after ${retries} attempts: ${err.message}`);
        }
      }
    }
  }

  throw new Error("Unexpected error in fetchWithBackoff");
}
