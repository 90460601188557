import React, { FC, useState, useEffect } from "react";
import Modal from "react-modal";
import { Document, Page, pdfjs } from "react-pdf";

// https://stackoverflow.com/questions/75104923/react-pdf-displaying-text-found-inside-the-pdf-instead-of-the-pdf-itself
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface FileModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  fileLink: string;
}

const FileModal: React.FC<FileModalProps> = ({
  isOpen,
  onRequestClose,
  fileLink,
}) => {
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowRight" && pageNumber < numPages!) {
      setPageNumber((prevPage) => prevPage + 1);
    } else if (event.key === "ArrowLeft" && pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, pageNumber, numPages]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File Preview"
      className="bg-white shadow-lg rounded-lg mx-auto my-10 relative"
      style={{
        content: {
          maxHeight: "90vh",
          maxWidth: "90vw",
          overflow: "auto",
        },
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <button
        onClick={onRequestClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        style={{ fontSize: "1.5rem", cursor: "pointer" }}
      >
        &times;
      </button>

      <div className="p-6">
        <div>
          <Document
            file={fileLink}
            onLoadSuccess={onDocumentLoadSuccess}
            className="w-full"
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        <p className="text-center mt-2">
          Page {pageNumber} of {numPages}
        </p>
        <div className="flex justify-center gap-4 mt-2">
          <button
            onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)}
            className="mr-2 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Previous
          </button>
          <button
            onClick={() =>
              setPageNumber(pageNumber < numPages! ? pageNumber + 1 : numPages!)
            }
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FileModal;
